body{
  overflow-x: hidden;
  height: 100%;
}

.login-page {
  padding-top: 30px;
  position: relative;
  min-height: 100vh;
  background-color: #F6F7F8;
}

.cuadro-login{
  box-shadow: 3px 3px rgba(0,0,0,.075);
}

@media only screen and (min-width: 600px) {
  .login-page {
    padding-top: 80px;
  }
}